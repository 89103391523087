<template>
  <b-card title="Vue d'ensemble">
    <app-echart-stacked-area
    :componentRef="depensesSalarialeEtChiffreAffaireChartRef"
      :option-data="chartOptions"
      :update="canUpdateChart"
      v-if="!isLoading"
    />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import AppEchartStackedArea from "@core/components/charts/echart/AppEchartStackedArea.vue";
import { mapActions, mapState } from "vuex";
import utilsService from "@/services/utils/utils.service";

const monthsList = utilsService.getListMonths();
const defaultSerieData = [{ type: "line",
            stack: "Total",
            areaStyle: {},
            showSymbol: false,
            lineStyle: {
              width: 0,
            },data: monthsList.map(e=> 0)}];

// console.log(monthsList);
const chartOptions = {
  xAxisData: monthsList,
  series: [...defaultSerieData
  ],
};
export default {
  components: {
    BCard,
    AppEchartStackedArea,
  },
  setup() {
    // return {utilsService}
  },
  data() {
    return {
      isLoading: false,
      canUpdateChart: false,
      chartOptions,
      monthsList,
            depensesSalarialeEtChiffreAffaireChartRef:'depensesSalarialeEtChiffreAffaireChartRef'

    };
  },
    props: {
    series: {
      type: Array,
      default: () => {
        return [...defaultSerieData];
      },
    },
  },
  computed: {},
  async mounted() {
    // this.isLoading = false
    // await this.getStatistics()
  },
    watch: {
    series: {
      deep: true, 
      handler(val, old) {
        // console.log(val);
        this.chartOptions = {
          ...chartOptions,
          series: val,
        };
        // this.$refs[this.depensesSalarialeEtChiffreAffaireChartRef].setOption(option, {});
      },
    },
  },
  methods: {
   
  },
}
</script>
<style lang="scss" scoped>
.echarts {
  width: 100%;
  height: 400px;
}
</style>
